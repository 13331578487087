import { ConsoleLogger } from 'aws-amplify/utils';

const logger = new ConsoleLogger('i18n');

const I18N_LANGUAGES = [
  'ar',
  'de',
  'en-gb',
  'en',
  'es',
  'fr',
  'id',
  'it',
  'ja',
  'ko',
  'pt-br',
  'th',
  'tr',
  'zh-cn',
  'zh-tw',
] as const satisfies string[];

type I18NLanguage = (typeof I18N_LANGUAGES)[number];

const I18N_FALLBACK_LANGUAGE: I18NLanguage = 'en';

const languages: string[] = I18N_LANGUAGES;

function isI18NLanguage(value: unknown): value is I18NLanguage {
  return typeof value === 'string' && languages.includes(value.toLowerCase());
}

export function getSupportedLocale(): I18NLanguage {
  const preferredLanguages = navigator.languages ?? [];
  logger.verbose(`Browser preferred languages: ${preferredLanguages.toString()}`);
  const supportedLanguages = preferredLanguages.filter((i) => isI18NLanguage(i));
  const locale = supportedLanguages.at(0) ?? I18N_FALLBACK_LANGUAGE;
  logger.debug(`Using locale: ${locale}`);
  return locale;
}
